<template>
  <div>
    <PageHeader
      has-return
      :page="{
        icon: 'fas fa-file-user',
        title: 'Información del Estudiante (MiPE)',
        routes: ['Inicio', 'Información del Estudiante (MiPE)'],
      }"
    />
    <StudentInformation :student-sie-id="studentSieId" />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import StudentInformation from '@/components/StudentInformation';

export default {
  name: 'Information',
  components: { PageHeader, StudentInformation },
  props: { studentSieId: { type: [String, Number], default: null } },
};
</script>
