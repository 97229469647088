<template>
  <div v-if="info" id="student-information">
    <b-tabs class="mipe-orange-tabs" content-class="pt-4" no-nav-style>
      <b-tab title="Información de MiPE">
        <SectionTitle has-line title="Información General" />

        <b-card body-class="pt-0" class="border-0">
          <b-row>
            <b-col cols="6" md="4">
              <UiDetailDisplay
                label="Nombre:"
                :text="info.studentInfo && info.studentInfo.fullname"
              />
            </b-col>
            <b-col cols="6" md="4">
              <UiDetailDisplay
                label="Número de Registro:"
                :text="info.studentInfo && info.studentInfo.registerNumber"
              />
            </b-col>
            <b-col class="mt-4 mt-md-0" cols="6" md="4">
              <UiDetailDisplay
                label="Estatus:"
                :text="info.studentInfo && info.studentInfo.status"
              />
            </b-col>

            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay
                label="Impedimento:"
                :text="info.studentInfo && info.studentInfo.impediment"
              />
            </b-col>
            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay
                label="Ubicación:"
                :text="info.studentInfo && info.studentInfo.location"
              />
            </b-col>
          </b-row>
        </b-card>

        <SectionTitle has-line title="Información del PEI" />

        <b-card body-class="pt-0" class="border-0">
          <b-row>
            <b-col cols="6" md="4">
              <UiDetailDisplay
                label="Número de PEI:"
                :text="info.peiInfo && info.peiInfo.number"
              />
            </b-col>
            <b-col cols="6" md="4">
              <UiDetailDisplay label="Fecha:">
                <template #default="{ placeholder }">
                  <span v-if="info.peiInfo && info.peiInfo.date">
                    {{ info.peiInfo.date | moment('DD/MMM/YYYY') }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>
            <b-col class="mt-4 mt-md-0" cols="6" md="4">
              <UiDetailDisplay
                label="Estatus:"
                :text="info.peiInfo && info.peiInfo.status"
              />
            </b-col>
          </b-row>
        </b-card>

        <SectionTitle has-line title="Información de Escuela/Ubicación" />

        <b-card body-class="pt-0" class="border-0">
          <b-row>
            <b-col cols="6" md="4">
              <UiDetailDisplay
                label="Región:"
                :text="info.locationInfo && info.locationInfo.region"
              />
            </b-col>
            <b-col cols="6" md="4">
              <UiDetailDisplay
                label="Distrito:"
                :text="info.locationInfo && info.locationInfo.district"
              />
            </b-col>
            <b-col class="mt-4 mt-md-0" cols="6" md="4">
              <UiDetailDisplay
                label="Municipio:"
                :text="info.locationInfo && info.locationInfo.municipality"
              />
            </b-col>
            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay
                label="Escuela:"
                :text="info.locationInfo && info.locationInfo.school"
              />
            </b-col>
            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay
                label="Grado:"
                :text="info.locationInfo && info.locationInfo.grade"
              />
            </b-col>
          </b-row>
        </b-card>

        <SectionTitle has-line title="Fechas" />

        <b-card body-class="pt-0" class="border-0">
          <b-row>
            <b-col cols="6" md="4">
              <UiDetailDisplay label="Referido al programa de EE:">
                <template #default="{ placeholder }">
                  <span v-if="info.datesInfo && info.datesInfo.referralDate">
                    {{ info.datesInfo.referralDate | moment('DD/MMM/YYYY') }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>
            <b-col cols="6" md="4">
              <UiDetailDisplay label="Consentimiento:">
                <template #default="{ placeholder }">
                  <span v-if="info.datesInfo && info.datesInfo.consent">
                    {{ info.datesInfo.consent | moment('DD/MMM/YYYY') }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>
            <b-col class="mt-4 mt-md-0" cols="6" md="4">
              <UiDetailDisplay label="Registro:">
                <template #default="{ placeholder }">
                  <span v-if="info.datesInfo && info.datesInfo.register">
                    {{ info.datesInfo.register | moment('DD/MMM/YYYY') }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>

            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay label="Evaluación Inicial:">
                <template #default="{ placeholder }">
                  <span
                    v-if="
                      info.datesInfo && info.datesInfo.initialEvaluationDate
                    "
                  >
                    {{
                      info.datesInfo.initialEvaluationDate
                        | moment('DD/MMM/YYYY')
                    }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>
            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay label="Determinación Inicial:">
                <template #default="{ placeholder }">
                  <span
                    v-if="
                      info.datesInfo && info.datesInfo.initialDeterminationDate
                    "
                  >
                    {{
                      info.datesInfo.initialDeterminationDate
                        | moment('DD/MMM/YYYY')
                    }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>
            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay label="PEI Inicial:">
                <template #default="{ placeholder }">
                  <span v-if="info.datesInfo && info.datesInfo.initialPeiDate">
                    {{ info.datesInfo.initialPeiDate | moment('DD/MMM/YYYY') }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>

            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay label="Ubicación Inicial:">
                <template #default="{ placeholder }">
                  <span
                    v-if="info.datesInfo && info.datesInfo.initialLocationDate"
                  >
                    {{
                      info.datesInfo.initialLocationDate | moment('DD/MMM/YYYY')
                    }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>
            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay label="Determinación Actual:">
                <template #default="{ placeholder }">
                  <span
                    v-if="
                      info.datesInfo && info.datesInfo.currentDeterminationDate
                    "
                  >
                    {{
                      info.datesInfo.currentDeterminationDate
                        | moment('DD/MMM/YYYY')
                    }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>
            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay label="Analisis de Evaluación Existente:">
                <template #default="{ placeholder }">
                  <span
                    v-if="
                      info.datesInfo &&
                        info.datesInfo.existingEvaluationAnalysisDate
                    "
                  >
                    {{
                      info.datesInfo.existingEvaluationAnalysisDate
                        | moment('DD/MMM/YYYY')
                    }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>

            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay label="Trianual:">
                <template #default="{ placeholder }">
                  <span v-if="info.datesInfo && info.datesInfo.triannualDate">
                    {{ info.datesInfo.triannualDate | moment('DD/MMM/YYYY') }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>
            <b-col class="mt-4" cols="6" md="4">
              <UiDetailDisplay label="Egreso:">
                <template #default="{ placeholder }">
                  <span v-if="info.datesInfo && info.datesInfo.egresoDate">
                    {{ info.datesInfo.egresoDate | moment('DD/MMM/YYYY') }}
                  </span>
                  <span v-else>
                    {{ placeholder }}
                  </span>
                </template>
              </UiDetailDisplay>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>

      <b-tab title="Matrícula">
        <custom-result-not-found
          v-if="info.enrollmentInfos.length === 0"
          text="Usted no cuenta con matrícula en el sistema."
        />
        <template v-for="(item, i) in info.enrollmentInfos" v-else>
          <UiCardListItem :key="i" border-indicator :class="{ 'mt-3': i > 0 }">
            <b-row>
              <b-col cols="12" md="1">
                <UiDetailDisplay label="Año:" :text="item.year" />
              </b-col>
              <b-col class="mt-4 mt-md-0" cols="12" md="2">
                <UiDetailDisplay label="Comienzo/Final:">
                  <div class="d-flex flex-md-column">
                    <span>
                      {{ item.initialYear | moment('DD/MMM/YYYY') }}
                    </span>
                    <span class="d-md-none mx-2 mx-md-0">-</span>
                    <span>{{ item.endYear | moment('DD/MMM/YYYY') }}</span>
                  </div>
                </UiDetailDisplay>
              </b-col>
              <b-col class="mt-4 mt-md-0" cols="12" md>
                <UiDetailDisplay
                  label="Región/Distrito:"
                  :text="item.localization"
                />
              </b-col>
              <b-col class="mt-4 mt-md-0" cols="12" md>
                <UiDetailDisplay label="Escuela:" :text="item.school" />
              </b-col>
              <b-col class="mt-4 mt-md-0" cols="12" md="1">
                <UiDetailDisplay label="Grado:" :text="item.grade" />
              </b-col>
              <b-col class="mt-4 mt-md-0" cols="12" md>
                <UiDetailDisplay label="Estatus:" :text="item.status" />
              </b-col>
            </b-row>
          </UiCardListItem>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import UiDetailDisplay from '@/components/ui/UiDetailDisplay';
import UiCardListItem from '@/components/ui/UiCardListItem';
import { ParentApi } from '@/api/parent.api';
import CustomResultNotFound from 'custom-result-not-found';

const parentApi = new ParentApi();

export default {
  name: 'StudentInformation',
  components: {
    SectionTitle,
    UiDetailDisplay,
    UiCardListItem,
    CustomResultNotFound,
  },
  props: { studentSieId: { type: [String, Number], default: null } },
  data: () => ({ info: null }),
  methods: {
    async getInfo() {
      const data = await parentApi.getChild(this.studentSieId);
      if (data) {
        this.info = data;
      }
    },
  },
  async created() {
    await this.getInfo();
  },
};
</script>

<style lang="scss" scoped>
#student-information ::v-deep {
  .nav-item {
    min-width: 15rem;
    text-align: center;

    .nav-link {
      font: 16px Lato;
      letter-spacing: 0;
      padding-bottom: 5px;

      &.active {
        font-weight: bold;
        color: #ff7058;
        border-bottom: 3px solid #ff7058;

        &::after {
          display: none;
        }
      }
    }
  }

  @media (max-width: 680px) {
    .nav-item {
      width: 100%;
      padding-bottom: 1rem;
    }
  }
}
</style>
