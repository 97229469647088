var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info)?_c('div',{attrs:{"id":"student-information"}},[_c('b-tabs',{staticClass:"mipe-orange-tabs",attrs:{"content-class":"pt-4","no-nav-style":""}},[_c('b-tab',{attrs:{"title":"Información de MiPE"}},[_c('SectionTitle',{attrs:{"has-line":"","title":"Información General"}}),_c('b-card',{staticClass:"border-0",attrs:{"body-class":"pt-0"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Nombre:","text":_vm.info.studentInfo && _vm.info.studentInfo.fullname}})],1),_c('b-col',{attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Número de Registro:","text":_vm.info.studentInfo && _vm.info.studentInfo.registerNumber}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Estatus:","text":_vm.info.studentInfo && _vm.info.studentInfo.status}})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Impedimento:","text":_vm.info.studentInfo && _vm.info.studentInfo.impediment}})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Ubicación:","text":_vm.info.studentInfo && _vm.info.studentInfo.location}})],1)],1)],1),_c('SectionTitle',{attrs:{"has-line":"","title":"Información del PEI"}}),_c('b-card',{staticClass:"border-0",attrs:{"body-class":"pt-0"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Número de PEI:","text":_vm.info.peiInfo && _vm.info.peiInfo.number}})],1),_c('b-col',{attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Fecha:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var placeholder = ref.placeholder;
return [(_vm.info.peiInfo && _vm.info.peiInfo.date)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.peiInfo.date,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,836691210)})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Estatus:","text":_vm.info.peiInfo && _vm.info.peiInfo.status}})],1)],1)],1),_c('SectionTitle',{attrs:{"has-line":"","title":"Información de Escuela/Ubicación"}}),_c('b-card',{staticClass:"border-0",attrs:{"body-class":"pt-0"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Región:","text":_vm.info.locationInfo && _vm.info.locationInfo.region}})],1),_c('b-col',{attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Distrito:","text":_vm.info.locationInfo && _vm.info.locationInfo.district}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Municipio:","text":_vm.info.locationInfo && _vm.info.locationInfo.municipality}})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Escuela:","text":_vm.info.locationInfo && _vm.info.locationInfo.school}})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Grado:","text":_vm.info.locationInfo && _vm.info.locationInfo.grade}})],1)],1)],1),_c('SectionTitle',{attrs:{"has-line":"","title":"Fechas"}}),_c('b-card',{staticClass:"border-0",attrs:{"body-class":"pt-0"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Referido al programa de EE:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var placeholder = ref.placeholder;
return [(_vm.info.datesInfo && _vm.info.datesInfo.referralDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.datesInfo.referralDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,2965424561)})],1),_c('b-col',{attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Consentimiento:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var placeholder = ref.placeholder;
return [(_vm.info.datesInfo && _vm.info.datesInfo.consent)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.datesInfo.consent,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,1841411921)})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Registro:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var placeholder = ref.placeholder;
return [(_vm.info.datesInfo && _vm.info.datesInfo.register)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.datesInfo.register,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,1190572913)})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Evaluación Inicial:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var placeholder = ref.placeholder;
return [(
                    _vm.info.datesInfo && _vm.info.datesInfo.initialEvaluationDate
                  )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.datesInfo.initialEvaluationDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,1690752657)})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Determinación Inicial:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var placeholder = ref.placeholder;
return [(
                    _vm.info.datesInfo && _vm.info.datesInfo.initialDeterminationDate
                  )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.datesInfo.initialDeterminationDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,731325265)})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"PEI Inicial:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var placeholder = ref.placeholder;
return [(_vm.info.datesInfo && _vm.info.datesInfo.initialPeiDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.datesInfo.initialPeiDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,2838254481)})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Ubicación Inicial:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var placeholder = ref.placeholder;
return [(_vm.info.datesInfo && _vm.info.datesInfo.initialLocationDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.datesInfo.initialLocationDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,563941457)})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Determinación Actual:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var placeholder = ref.placeholder;
return [(
                    _vm.info.datesInfo && _vm.info.datesInfo.currentDeterminationDate
                  )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.datesInfo.currentDeterminationDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,1210499473)})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Analisis de Evaluación Existente:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var placeholder = ref.placeholder;
return [(
                    _vm.info.datesInfo &&
                      _vm.info.datesInfo.existingEvaluationAnalysisDate
                  )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.datesInfo.existingEvaluationAnalysisDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,261320059)})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Trianual:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var placeholder = ref.placeholder;
return [(_vm.info.datesInfo && _vm.info.datesInfo.triannualDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.datesInfo.triannualDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,230126289)})],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"6","md":"4"}},[_c('UiDetailDisplay',{attrs:{"label":"Egreso:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var placeholder = ref.placeholder;
return [(_vm.info.datesInfo && _vm.info.datesInfo.egresoDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.info.datesInfo.egresoDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,false,1840087217)})],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Matrícula"}},[(_vm.info.enrollmentInfos.length === 0)?_c('custom-result-not-found',{attrs:{"text":"Usted no cuenta con matrícula en el sistema."}}):_vm._l((_vm.info.enrollmentInfos),function(item,i){return [_c('UiCardListItem',{key:i,class:{ 'mt-3': i > 0 },attrs:{"border-indicator":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"1"}},[_c('UiDetailDisplay',{attrs:{"label":"Año:","text":item.year}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":"2"}},[_c('UiDetailDisplay',{attrs:{"label":"Comienzo/Final:"}},[_c('div',{staticClass:"d-flex flex-md-column"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.initialYear,'DD/MMM/YYYY'))+" ")]),_c('span',{staticClass:"d-md-none mx-2 mx-md-0"},[_vm._v("-")]),_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.endYear,'DD/MMM/YYYY')))])])])],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"label":"Región/Distrito:","text":item.localization}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"label":"Escuela:","text":item.school}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":"1"}},[_c('UiDetailDisplay',{attrs:{"label":"Grado:","text":item.grade}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"label":"Estatus:","text":item.status}})],1)],1)],1)]})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }